<template>
  <TunnelLayout>
    <template #form>
      <div class="flex flex-col">
        <h2 class="font-bold text-3xl text-promy-blue-300 sm:text-base">
          Souhaitez vous apportez des <br />
          informations complémentaire
        </h2>
        <div class="flex mt-8 space-x-4 items-baseline w-full">
          <ProTextarea
            class="w-full"
            label="Dites-nous"
            placeholder="Description"
            v-model="form.informations.more_informations"
          />
        </div></div
    ></template>
    <template #buttons> <Buttons :form="form" :goNext="invalid" /></template>

    <template slot="right-side">
      <Map :showBtnParcelle="false" :form="form" :cadastre="cadastre" />
    </template>
  </TunnelLayout>
</template>
<script>
import Buttons from './components/Buttons.vue'
import Map from './components/Map.vue'

export default {
  components: {
    Buttons,
    Map,
  },
  props: {
    form: {
      type: Object,
      required: true,
    },
    cadastre: {
      type: Object,
      require: true,
    },
    invalid: Boolean,
  },
}
</script>
